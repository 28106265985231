import axios from "axios";
import { Message } from "element-ui";
import router from "../router";
const instance = axios.create({
  // baseURL: '/api',
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});
// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    // && config.url !== "/auth/login"
    if (token && config.url !== "/auth/login") {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code == 401) {
      Message({
        message: res.msg,
        type: "error",
      });
      setTimeout(() => {
        router.push("/login");
      }, 2000);
      return Promise.reject("error");
    } else {
      if (res.code == 1) {
        return res;
      } else {
        Message({
          message: res.msg,
          type: "error",
        });
        return Promise.reject("error");
      }
    }
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;

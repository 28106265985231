<template>
  <div class="container">
    <div class="main">
      <div class="header_box flexSb">
        <div class="flexSt">
          <div class="flexSt list2">
            <div class="title">年级/班级</div>
            <el-cascader
              placeholder="年级/班级"
              :options="options"
              v-model="form.grade"
              :props="{ children: 'class', multiple: true }"
              @change="handleChange"
              filterable
              clearable></el-cascader>
          </div>
          <div class="flexSt">
            <div class="title">学生名称</div>
            <el-input
              clearable
              v-model="form.name"
              placeholder="请输入学生名称"></el-input>
          </div>
          <div class="flexSt ses">
            <div class="title">班号</div>
            <el-input
              clearable
              v-model="form.number"
              placeholder="请输入班号"></el-input>
          </div>
          <div class="flexSt ses">
            <div class="title">性别</div>
            <el-select v-model="form.gender" placeholder="请选择性别">
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="0"></el-option>
            </el-select>
          </div>
          <div class="flexSt">
            <div class="title">体育特长</div>
            <el-select
              clearable
              v-model="form.sports_speciality"
              placeholder="请选择体育特长">
              <el-option
                v-for="(item, index) in sportSpecialitylist"
                :key="item.num"
                :label="item.name"
                :value="item.num"></el-option>
            </el-select>
          </div>
        </div>
        <div class="flexSb">
          <el-button @click="handleSearch" type="primary" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="handleclearSearch" icon="el-icon-refresh"
            >重置</el-button
          >
        </div>
      </div>

      <div class="add flexSe">
        <!-- <div class="flexSt">
        <el-button
          @click="handleTo('/editStudent')"
          type="primary"
          icon="el-icon-plus"
          >添加学生</el-button
        ><el-button icon="el-icon-delete">删除</el-button>
      </div> -->
        <div class="flexSb">
          <el-button icon="el-icon-download">导入</el-button>
          <el-button icon="el-icon-upload2">导出</el-button>
        </div>
      </div>
      <div class="table_box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName">
          <el-table-column type="selection" width="25"> </el-table-column>
          <el-table-column
            :index="indexMethod"
            type="index"
            label="序号"
            width="60"
            align="center">
          </el-table-column>

          <!-- <el-table-column>
            <template slot-scope="scope">
              <img class="img" src="../../../assets//img/1633.png" alt="" />
            </template>
          </el-table-column> -->
          <el-table-column label="用户信息" width="170">
            <template slot-scope="scope">
              <div class="flexSc">
                <a v-if="!scope.row.user_pic" href="">
                  <img
                    v-if="scope.row.gender == 0"
                    class="img"
                    src="../../../assets/img/1633.png"
                    alt="" />
                  <img
                    v-else
                    class="img"
                    src="../../../assets/img/1634.png"
                    alt="" />
                </a>
                <a v-else href="">
                  <img class="img" :src="scope.row.user_pic" alt="" />
                </a>
                <div>
                  <div class="flexSt">
                    <a href="">
                      <div class="name">{{ scope.row.name }}</div>
                    </a>

                    <img
                      v-if="scope.row.gender == 0"
                      class="img_gender"
                      src="../../../assets/img/207.png"
                      alt="" />
                    <img
                      v-else
                      class="img_gender"
                      src="../../../assets/img/206.png"
                      alt="" />
                  </div>
                  <div>班号：{{ scope.row.number }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="grade"
            label="年级"
            align="center"
            sortable
            width="90">
            <template slot-scope="scope">
              <div style="padding-right: 16px">
                {{ scope.row.grade ? scope.row.grade + "年级" : "- -" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="所在班级"
            sortable
            align="center"
            width="110"
            prop="class_name">
            <template slot-scope="scope">
              <div style="padding-right: 16px">
                {{ scope.row.class_name ? scope.row.class_name : "- -" }}
              </div>
            </template></el-table-column
          >
          <el-table-column
            prop="age"
            label="年龄"
            sortable
            width="80"
            align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">
                {{ scope.row.age ? scope.row.age + "岁" : "- -" }}
              </div>
            </template></el-table-column
          >
          <!-- width="190" -->
          <el-table-column min-width="267" label="体育特长" align="center">
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.sports_speciality_name &&
                  scope.row.sports_speciality_name.length > 0
                "
                class="flexSc tag_box">
                <div
                  v-for="(item, index) in scope.row.sports_speciality_name"
                  :key="item">
                  <el-tag v-if="index <= 4" class="tag" type="info">{{
                    item
                  }}</el-tag>
                  <el-tag v-else class="tag" type="info">...</el-tag>
                </div>
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="sports_class_rate"
            label="体育课出勤率"
            align="center"
            sortable
            min-width="140"
            ><template slot-scope="scope">
              <div style="padding-right: 16px">
                {{
                  scope.row.sports_class_rate
                    ? scope.row.sports_class_rate
                    : "- -"
                }}
              </div>
            </template>
            <!-- width="140px" --> </el-table-column
          ><el-table-column
            prop="big_break_rate"
            label="大课间出勤率"
            align="center"
            sortable
            min-width="140">
            <template slot-scope="scope">
              <div style="padding-right: 16px">
                {{
                  scope.row.big_break_rate ? scope.row.big_break_rate : "- -"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="homework_rate"
            label="作业完成率"
            align="center"
            sortable
            min-width="140">
            <template slot-scope="scope">
              <div style="padding-right: 16px">
                {{ scope.row.homework_rate ? scope.row.homework_rate : "- -" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            fixed="right"
            label="操作"
            align="center">
            <template slot-scope="scope">
              <div class="flexSc">
                <el-button @click="handleDetail(scope.row)" type="text"
                  >综合评价</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="flexSe elpla">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.page"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  studentInfolist,
  gradeClassList,
  sportSpeciality,
} from "@/api/evaluation";
export default {
  data() {
    return {
      sportSpecialitylist: [],
      schoolist: [
        {
          label: "全区",
          value: -1,
        },
      ],
      form: {
        name: "",
        number: "",
        gender: -1,
        grade: [],
        class_id: "",
        sports_speciality: -1,
      },
      options: [],
      pageInfo: {
        pageSize: 10,
        page: 1,
        total: 0,
      },
      tableData: [],
    };
  },
  watch: {
    $route: function (to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
      this.WatchResize();
      this.$forceUpdate();
      this.handleGetsportSpeciality();
      this.handleGetGradeClassList();
      this.handleGetStudentInfolist();
    });
  },
  computed: {
    indexMethod() {
      // 当前页数 - 1 * 每页数据条数 + 1
      return (this.pageInfo.page - 1) * this.pageInfo.pageSize + 1;
    },
  },
  methods: {
    WatchResize() {
      const that = this;
      window.addEventListener("resize", function () {});
    },
    async handleGetsportSpeciality() {
      await sportSpeciality().then((res) => {
        this.sportSpecialitylist = res.data;
        this.sportSpecialitylist.unshift({
          num: -1,
          name: "全部",
        });
      });
    },

    handleTo(url) {
      this.$router.push(url);
    },
    handleSearch() {
      this.pageInfo.page = 1;
      this.handleGetStudentInfolist();
    },
    handleclearSearch() {
      this.form = {
        name: "",
        grade: [],
        mobile: "",
        class_id: "",
        gender: -1,
        status: -1,
      };

      this.pageInfo.page = 1;
      this.handleGetStudentInfolist();
    },
    handleChange(val) {
      console.log(val);
      if (val.length > 0) {
        this.form.class_id = val[1];
      } else {
        this.form.class_id = "";
      }
      this.handleSearch();
    },
    async handleGetStudentInfolist() {
      let data = {
        name: this.form.name,
        number: this.form.number,
        gender: this.form.gender,
        class_id: this.form.grade[1],
        page: this.pageInfo.page,
        per_page: this.pageInfo.pageSize,
      };
      await studentInfolist(data).then((res) => {
        console.log(res);
        this.tableData = res.data.data;
        this.pageInfo.total = res.data.total;
      });
    }, // 年级班级
    async handleGetGradeClassList() {
      await gradeClassList().then((res) => {
        this.options = res.data;
      });
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    handleDetail(val) {
      this.$router.push("/studentLiteracydetail?id=" + val.id);
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.handleGetStudentInfolist();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      console.log(this.pageInfo.page, "    this.pageInfo.page");
      this.handleGetStudentInfolist();
    },
    handleSelectionChange() {
      // this.handleSearch();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.container {
  box-sizing: border-box;

  /deep/.firstCell {
    .cell {
      overflow: unset !important;
    }
  }
  // background-color: #fff;
  // min-height: 100%;
  // overflow-y: auto;
  // padding: 36px 25px;
  // box-sizing: border-box;
  .elpla {
    margin-top: 30px;
  }

  .header_box {
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    padding-bottom: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: flex-start;
    .box222 {
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .list2 {
      /deep/.el-cascader {
        width: 147px !important;
        margin-right: 10px;
      }
    }
    .title22 {
      margin-left: 12px;
    }
    /deep/.title {
      color: rgb(78, 89, 105);
      font-size: 14px;
      margin-right: 8px;
      min-width: 60px;
    }
    .ses {
      /deep/.title {
        min-width: 30px;
      }
    }
    .sex {
      /deep/.el-form-item__label {
        width: 47px !important;
      }
      /deep/.el-form-item__content {
        margin-left: 60px !important;
      }
      /deep/.el-select {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        width: 127px !important;
      }
    }
    .sex2 {
      /deep/.el-form-item__label {
        width: 80px !important;
      }
      /deep/.el-form-item__content {
        width: 167px !important;
      }
      /deep/.el-select {
        width: 147px !important;
        height: 32px !important;
      }
    }
    .sex3 {
      /deep/.el-select,
      /deep/.el-input__inner {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        // margin-left: 40px !important;
      }
      /deep/.el-input {
        width: 127px !important;
      }
    }
    /deep/.el-button {
      padding: 0 20px;
      // height: 30px !important;
      // line-height: 30px;
      height: 40px !important;
      line-height: 40px;
    }

    /deep/.el-form-item__content,
    /deep/.el-form-item__labe {
      height: 32px !important;
      width: 147px;
    }
    // /deep/.el-input__inner,   /deep/.el-input,
    /deep/.el-input {
      margin-right: 15px;
    }
    /deep/.el-input__inner,
    /deep/.el-input {
      width: 147px;
    }

    /deep/.el-input__icon {
      line-height: 32px !important;
    }

    /deep/.el-form-item__content {
      width: 171px !important;
    }
  }
  .add {
    margin: 17px 0;
    /deep/.el-button {
      padding: 0 20px;
      height: 30px !important;
      line-height: 30px;
    }
  }
  .table_box {
    a {
      cursor: pointer;
    }
    .img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 14px;
    }
    .img_gender {
      width: 20px;
      height: 20px;
    }
    .name {
      font-size: 14px;
      color: #1890ff;
      margin-right: 6px;
    }
    .tag_box {
      flex-wrap: wrap;
      .tag {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
  // ::-webkit-scrollbar {
  //   width: 10px !important;
  //   height: 10px !important;
  //   background-color: #ccc;
  // }
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    // background-color: #ccc !important;
  }
  ::-webkit-scrollbar-track {
    // background-color: #bbbbbb;
  }
  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}
</style>

import { baseUrl } from "@/utils/config";
import request from "@/utils/request";

// 学年列表
export function schoolYear(data) {
  return request({
    url: baseUrl + "/teaching/schoolYear",
    method: "get",
    params: data,
  });
}
// 分层教学模型
// 分层教学模型 占比
export function teaching(data) {
  return request({
    url: baseUrl + "/teaching",
    method: "get",
    params: data,
  });
}

// 趋势图
export function teachingTrend(data) {
  return request({
    url: baseUrl + "/teaching/trend",
    method: "get",
    params: data,
  });
}

// 运动能力列表
export function getSportsList(data) {
  return request({
    url: baseUrl + "/teaching/sportsList",
    method: "get",
    params: data,
  });
}

// 各年级成绩所有类型人数
export function sportsInfo(data) {
  return request({
    url: baseUrl + "/teaching/sportsInfo",
    method: "get",
    params: data,
  });
}

// 分层教学学生列表
export function teachingStudentInfo(data) {
  return request({
    url: baseUrl + "/teaching/studentInfo",
    method: "get",
    params: data,
  });
}

// 教师列表页 相关
// 教师列表
export function getTeacher(data) {
  return request({
    url: baseUrl + "/teacher",
    method: "get",
    params: data,
  });
}

// 年级班级
export function gradeClassList(data) {
  return request({
    url: baseUrl + "/teachers/gradeClassList",
    method: "get",
    params: data,
  });
}

// 删除老师
export function delTeacher(data) {
  return request({
    url: baseUrl + "/teacher/" + data.id,
    method: "delete",
    data: data,
  });
}

// 添加教师
export function addTeacher(data) {
  return request({
    url: baseUrl + "/teacher",
    method: "post",
    data: data,
  });
}

// 添加教师下拉款
export function teacherInfoData(data) {
  return request({
    url: baseUrl + "/teachers/infoData",
    method: "get",
    params: data,
  });
}

// 编辑教师
export function editTeacher(data) {
  return request({
    url: baseUrl + "/teacher/" + data.id,
    method: "put",
    data: data,
  });
}

// 教师导入
export function importTeacher(data) {
  return request({
    url: baseUrl + "/teachers/import",
    method: "post",
    data: data,
  });
}

// 老师综合评价详情页面

// 老师详情
export function teachersInfo(data) {
  return request({
    url: baseUrl + "/teachers/info",
    method: "get",
    params: data,
  });
}

// 老师详情页-综合评价
export function teachersOverview(data) {
  return request({
    url: baseUrl + "/teachers/overview",
    method: "get",
    params: data,
  });
}

//  老师详情页-核心指标
export function teachersCoreIndicators(data) {
  return request({
    url: baseUrl + "/teachers/coreIndicators",
    method: "get",
    params: data,
  });
}

// 老师详情页-树形图
export function teachersBarChart(data) {
  return request({
    url: baseUrl + "/teachers/barChart",
    method: "get",
    params: data,
  });
}

// 老师详情-工作能力
export function teachersAbilityWork(data) {
  return request({
    url: baseUrl + "/teachers/abilityWork",
    method: "get",
    params: data,
  });
}

// 教师综合评价页面

// 统计
export function teachersOverviewStatistics(data) {
  return request({
    url: baseUrl + "/teachersOverview/statistics",
    method: "get",
    params: data,
  });
}
// 体质健康双达标变化趋势
export function teachersOverviewPhysicalHealth(data) {
  return request({
    url: baseUrl + "/teachersOverview/physicalHealth",
    method: "get",
    params: data,
  });
}

// 综合评价
export function teachersOverviewOverview(data) {
  return request({
    url: baseUrl + "/teachersOverview/overview",
    method: "get",
    params: data,
  });
}

// 教师情况
export function teachersOverviewSituation(data) {
  return request({
    url: baseUrl + "/teachersOverview/situation",
    method: "get",
    params: data,
  });
}

// 教学教案变化趋势
export function teachersOverviewLessonPlans(data) {
  return request({
    url: baseUrl + "/teachersOverview/lessonPlans",
    method: "get",
    params: data,
  });
}

// 竞赛获奖排行
export function teachersOverviewMatchWinningRank(data) {
  return request({
    url: baseUrl + "/teachersOverview/matchWinningRank",
    method: "get",
    params: data,
  });
}

// 竞赛参与情况
export function teachersOverviewMatchJoinList(data) {
  return request({
    url: baseUrl + "/teachersOverview/matchJoinList",
    method: "get",
    params: data,
  });
}

// 教师出勤情况
export function TeacherAttendanceInfo(data) {
  return request({
    url: baseUrl + "/teachersOverview/teacherAttendanceInfo",
    method: "get",
    params: data,
  });
}
// 学生核心素养页面

// 体质健康双达标变化趋势
export function studentCoreCompetenciesPhysicalHealth(data) {
  return request({
    url: baseUrl + "/studentCoreCompetencies/physicalHealth",
    method: "get",
    params: data,
  });
}
//学生核心素养
export function studentInfoCoreCompetencies(data) {
  return request({
    url: baseUrl + "/studentCoreCompetencies/coreCompetencies",
    method: "get",
    params: data,
  });
}

//学生情况
export function studentCoreCompetenciesStudentSituation(data) {
  return request({
    url: baseUrl + "/studentCoreCompetencies/studentSituation",
    method: "get",
    params: data,
  });
}

// 综合评价
export function studentCoreCompetenciesOverview(data) {
  return request({
    url: baseUrl + "/studentCoreCompetencies/overview",
    method: "get",
    params: data,
  });
}

// 课后一小时数据趋势 - 出勤率
export function studentCoreCompetenciesLessonInfo(data) {
  return request({
    url: baseUrl + "/studentCoreCompetencies/lessonInfo",
    method: "get",
    params: data,
  });
}
// 课后一小时数据趋势 - 家庭作业完成率
export function studentCoreCompetenciesHomeworkInfo(data) {
  return request({
    url: baseUrl + "/studentCoreCompetencies/homeworkInfo",
    method: "get",
    params: data,
  });
}

// 学生页面
// 学生列表
export function studentInfolist(data) {
  return request({
    url: baseUrl + "/studentInfo",
    method: "get",
    params: data,
  });
}

// 证件类型
export function idCardType(data) {
  return request({
    url: baseUrl + "/studentInfos/idCardType",
    method: "get",
    params: data,
  });
}

// 体育特长类型集合
export function sportSpeciality(data) {
  return request({
    url: baseUrl + "/studentInfos/sportSpeciality",
    method: "get",
    params: data,
  });
}

// 监护人类型集合
export function fiduciary(data) {
  return request({
    url: baseUrl + "/studentInfos/fiduciary",
    method: "get",
    params: data,
  });
}

// 添加学生
export function addStudentInfo(data) {
  return request({
    url: baseUrl + "/studentInfo",
    method: "post",
    data: data,
  });
}

// 编辑学生
export function editStudentInfo(data) {
  return request({
    url: baseUrl + "/studentInfo/" + data.row.id,
    method: "put",
    data: data,
  });
}

// 删除学生
export function delStudentInfo(data) {
  return request({
    url: baseUrl + "/studentInfo",
    method: "delete",
    data: data,
  });
}

// 学生综合评价页面
// 学生详情
export function studentInfo(data) {
  return request({
    url: baseUrl + "/studentInfos/info",
    method: "get",
    params: data,
  });
}
// 综合评价
export function studentInfoOverview(data) {
  return request({
    url: baseUrl + "/studentInfos/overview",
    method: "get",
    params: data,
  });
}

// 分层预览
export function studentInfoScoreType(data) {
  return request({
    url: baseUrl + "/studentInfos/scoreType",
    method: "get",
    params: data,
  });
}

// 身体素质雷达图
export function studentInfoRadarChart(data) {
  return request({
    url: baseUrl + "/studentInfos/radarChart",
    method: "get",
    params: data,
  });
}

// 国家体质健康
export function studentInfoPhysicalHealth(data) {
  return request({
    url: baseUrl + "/studentInfos/physicalHealth",
    method: "get",
    params: data,
  });
}

// 作业完成情况
export function homeworkInfo(data) {
  return request({
    url: baseUrl + "/studentInfos/homeworkInfo",
    method: "get",
    params: data,
  });
}

// 班级列表
export function getClass(data) {
  return request({
    url: baseUrl + "/class",
    method: "get",
    params: data,
  });
}

// 任课教师列表
export function getClassTeacherList(data) {
  return request({
    url: baseUrl + "/class/teacherList",
    method: "get",
    params: data,
  });
}
